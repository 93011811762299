.button.primary {
    background-color: $color-primary !important;
}

.button.secondary {
    background-color: $color-yellow;
}

.button.white {
    background-color: $white;
    color: $color-primary;

    &.hollow {
        border: 1px solid $white;
        background-color: transparent;
        color: $white;

        &:hover {
            text-decoration: none;
            background-color: white;
            color: $color-primary;
        }
    }
}

.button.short {
    padding-left: 2em;
    padding-right: 2em;
}

.login_button {
    min-width: 150px;
    max-width: 150px;
    overflow-x: hidden;
}
