/*.button.warning{
    @include button-style($color-secondary, auto, $white);
}*/

.button {
    border-radius: 5em / 5em;
    padding-left: 5em;
    padding-right: 5em;
    font-weight: 600;
}

.button.large {
    font-size: 1rem;
}
