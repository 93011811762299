$white: rgba(255, 255, 255, 1);
$color-primary: #4180ab;
$color-primary-hover: #265474;
$color-secondary: #bdd1de;
$color-aux: #f2f6f8;
$color-text: #3c3c3c;
$color-text-alt: #5c5c5c;
$color-yellow: #FFC107;
$color-green: #28A745;
$color-red: #DC3545;
$color-lightgray: rgb(245, 245, 245);
$color-pink: #bb32bf;
$color-orange: #e6731c;
$color-purple: #670ec7;

%background-1 {
    background-image: url('../../img/site/header.png');
    background-repeat: no-repeat;
    background-position: right;
    background-size: cover;
}

%background-2 {
    background-image: url('../../img/site/discover.png');
    background-repeat: no-repeat;
    background-position: right;
    background-size: cover;
}

%background-3 {
    background-image: url('../../img/site/step1.png');
    background-repeat: no-repeat;
    background-position: center;
}

%background-4 {
    background-image: url('../../img/site/step2.png');
    background-repeat: no-repeat;
    background-position: center;
}

%background-5 {
    background-image: url('../../img/site/step3.png');
    background-repeat: no-repeat;
    background-position: center;
}

%background-6 {
    background-image: url('../../img/site/header-os.png');
    background-repeat: no-repeat;
    background-position: right;
    background-size: cover;
}

%background-7 {
    background-image: url('../../img/site/cf-buildings.png');
    background-repeat: no-repeat;
    background-position: right;
    background-size: cover;
}

%background-8 {
    background-image: url('../../img/site/step4.png');
    background-repeat: no-repeat;
    background-position: center;
}

%background-9 {
    background-image: url('../../img/site/header-au.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

%background-10 {
    background-image: url('../../img/site/header-login.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

%background-11 {
    background-image: url('../../img/site/header-terms.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.bg-primary {
    background-color: $color-primary !important;
}

.bg-secondary {
    background-color: $color-secondary !important;
}

.bg-aux {
    background-color: $color-aux !important;
}
