@font-face {
    font-family: Raleway;
    src: url('../fonts/Raleway/Raleway-Regular.ttf');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: Raleway;
    src: url('../fonts/Raleway/Raleway-Black.ttf');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: Raleway;
    src: url('../fonts/Raleway/Raleway-BlackItalic.ttf');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: Raleway;
    src: url('../fonts/Raleway/Raleway-Bold.ttf');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: Raleway;
    src: url('../fonts/Raleway/Raleway-BoldItalic.ttf');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: Raleway;
    src: url('../fonts/Raleway/Raleway-Italic.ttf');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: Raleway;
    src: url('../fonts/Raleway/Raleway-Light.ttf');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: Raleway;
    src: url('../fonts/Raleway/Raleway-LightItalic.ttf');
    font-weight: 300;
    font-style: italic;
}

.uppercase{
    text-transform: uppercase;
}
