@import '~foundation-sites/scss/util/util';
@import 'front/settings';
@import '~foundation-sites/scss/foundation';
@include foundation-everything;
@import 'front/color';
@import 'front/overrides';
@import 'front/fonts';
@import 'front/buttons';
@import 'front/forms';
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/css/all';

body {
    background-color: $white;
    color: $color-text;
    font-family: 'Raleway', sans-serif;
}

.header {
    padding-top: 20px;
    padding-bottom: 10px;
    text-transform: uppercase;

    a {
        color: $white;
    }

    a:hover {
        text-decoration: underline;
    }
}

.vertical>li>a {
    color: #4180ab !important;
}

.newsletter-form:first-child {
    margin-right: 200px !important;
}

.header-menu {
    font-size: 0.9em;
    font-weight: 600;

    .button {
        text-transform: none;
    }
}

.button.primary {
    background-color: #4180ab !important;
}

.discover-more-btn,
.discover-more-btn:hover,
.discover-more-btn:focus {
    margin-top: 20px;
    border: 2px solid #4180ab;
    color: #4180ab;
    background-color: rgba(0, 0, 0, 0);
}

.block {
    padding-top: 6em;
    padding-bottom: 6em;

    h2 {
        color: $color-primary;
        font-weight: 300;
        font-size: 2em;
    }

    h3 {
        font-size: 1.8em;
        font-weight: 300;
        color: $color-text;
    }

    h4 {
        font-size: 1.5em;
        font-weight: 300;
        color: $color-text;
    }

    p {
        font-weight: 300;
    }
}

.block-top {
    margin-top: -70px;
}

.separator,
.separator-services {
    width: 100%;
    height: 50px;
    display: block;
}

.extendedseparator {
    width: 100%;
    height: 100px;
    display: block;
}

.extendedseparator-services {
    width: 100%;
    display: block;
    padding-top: 100px;
}

.block-image {
    padding-top: 15em;

    h1 {
        color: $white;
        font-weight: 300;
    }

    h2 {
        font-size: 1.2em;
        color: $white;
        font-weight: 600;
    }

    p {
        color: $white;
        font-weight: 300;
    }

    a {
        color: $white;
    }
}

.block-image-login {
    padding-top: 5em;

    h1 {
        color: $white;
        font-weight: 300;
    }

    h2 {
        font-size: 1.2em;
        color: $white;
        font-weight: 600;
    }

    p {
        color: $white;
        font-weight: 300;
    }
}

.background-header {
    @extend %background-1;
}

.background-discover {
    @extend %background-2;

    h1 {
        color: $color-text;
    }

    h2 {
        color: $color-text;
    }

    p {
        color: $color-text;
    }

    padding-top:5em !important;
}

p,
li {
    color: $color-text;
}

h1 {
    font-size: 44px;
    margin-top: 50px;
}

h2 {
    font-size: 1.5em;
    font-weight: bold;
    color: $color-text;
}

.separator-center {
    background-color: $color-primary;
    height: 2px;
    position: relative;
    width: 22px;
    display: block;
    margin-left: 50%;
    left: -11px;
    margin-bottom: 30px;

    &-white {
        background-color: $white;
    }

}

.separator-left {
    background-color: $color-text;
    height: 2px;
    position: relative;
    width: 22px;
    display: block;
    margin: 10px 0 20px 0;
}

.round-icon {
    width: 60px;
    height: 60px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 20px;
    background-size: contain;
}

.round-1 {
    @extend %background-3;
}

.round-2 {
    @extend %background-4;
}

.round-3 {
    @extend %background-5;
}

.round-4 {
    @extend %background-8;
}

.footer {
    background-color: $color-aux;
    color: $color-text;
    padding-top: 4em;
    padding-bottom: 4em;

    .cell {
        margin-bottom: 40px;
    }
}

.menu-social {
    li {
        margin-right: 15px;
    }

    a {
        width: 40px;
        height: 40px;
        border-radius: 20px;
        padding-top: 10px;
        padding-left: 11px;
        font-size: 1.3em;
        background-color: $color-secondary;
        color: $white;
    }
}

.newsletter-form {
    input[type=email] {
        width: 200px;
        height: 40px;
        border-radius: 5em / 5em;
        border: 1px solid $color-secondary;
        display: inline-block;

        @include breakpoint(medium) {
            width: 300px;
        }
    }

    input[type=submit] {
        display: inline-block;
        margin-top: 5px;
    }
}

.background-ourservices {
    @extend %background-6;
}

.background-ourservices-2 {
    @extend %background-7;

    padding-top: 5em;
    padding-bottom: 5em;
}

.background-aboutus {
    @extend %background-9;
}

.background-login {
    @extend %background-10;
}

.background-terms {
    @extend %background-11;
}

.image-container {
    width: 150px;
    height: 100px;
    text-align: center;
    position: relative;
    margin-left: 50%;
    left: -75px;
}

.image-round-placeholder {
    height: 200px;
    position: relative;
    text-align: center;
}

.hr {
    width: 100%;
    height: 1px;
    background-color: $color-secondary;
    display: block;
    margin-top: 10px;
    margin-bottom: 30px;
}

.about-section {
    margin-top: 30px;
}

.about-padding {
    padding-top: 80px;
}

.about img {
    width: 80%;
}

.policy-title {
    margin-top: 30px;
}

.blue-bullet-list {
    list-style-image: url('../img/site/bullet-img.png');
}

#intro-text-line {
    height: 1px;
    width: 100%;
    background-color: #bdd1de;
    margin-top: 80px;
    margin-bottom: 20px;
}

.grid-container {
    max-width: 70rem;
}

label.required::before {
    content: "* ";
    color: $color-red;
    font-size: 1rem;
    font-weight: bold;
}

@media only screen and (max-width: 990px) {
    .separator-services {
        height: 100px
    }
}

@media only screen and (max-width: 860px) {
    .separator-services {
        height: 180px
    }
}

@media only screen and (max-width: 786px) {
    .separator-services {
        height: 230px
    }
}

@media only screen and (max-width: 786px) {
    .separator-services {
        height: 100px
    }

    .extendedseparator-services:nth-of-type(1) {
        display: none;
    }

    .mobile-padding-img {
        margin-bottom: 30px;
    }
}
