.generalForm {
    margin-top: 30px;
    margin-bottom: 30px;

    label {
        text-align: left;
        text-transform: lowercase;
        color: $color-primary;
    }

    input,
    textarea {
        border: none;
        box-shadow: none;
        margin-bottom: 0px;
    }

    input:focus,
    textarea:focus {
        border: none;
        box-shadow: none;
    }

    .underline {
        border-bottom: 1px solid $color-primary;
        margin-bottom: 20px;
    }

    button {
        margin-top: 30px;
    }

    .alert,
    .alert-danger {
        color: $color-red;
        margin-bottom: 20px;
    }
}
